
import Routing from './Router/router';

function App() {
  return (
    <><Routing /></>
  );
}

export default App;
